import React from 'react';
// import QuotelessFrontend from './QuotelessFrontend';
import Homepage from './Homepage';

function App() {
  return (
    <div className="App">
      < Homepage />
      {/* <QuotelessFrontend /> */}
    </div>
  );
}

export default App;